import { useAuth0 } from '@auth0/auth0-react';
import Button from "@mui/material/Button";
import Chip from "@mui/material/Chip";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../assets/css/grid.css";
import UserResource from "../resources/UserResource";

export default function Users() {
    const [loading, setLoading] = useState(false)
    const { getIdTokenClaims } = useAuth0()
    const [token, setToken] = useState(getIdTokenClaims())
    const location = useLocation()
    const navigate = useNavigate()
    const params = new URLSearchParams(location.search)
    const urlPage = params.get("page")
    const urlSize = params.get("perPage")
    const [users, setUsers] = useState({ Data: [], PageNo: 0, PageSize: urlSize ?? 20 })
    const [processing, setProcessing] = useState(null)

    // const getStatatus = (appt) => {
    //     let status = ''
    //     if (!appt.Paymentid)
    //         status = "Not Published"
    //     if (!appt.IsAssigned)
    //         status = 'Open'
    //     if (appt.Completed)
    //         status = 'Submitted'
    //     if (appt.ApproveCompletion)
    //         status = "Approved"
    //     if (appt.IsCanceled)
    //         status = "Canceled"
    //     return status
    // }

    // const processPayout = async (appointment_id) => {
    //     setLoading(true)
    //     setProcessing(appointment_id)
    //     let token = await getIdTokenClaims()
    //     token = token.__raw
    //     setToken(token)
    //     let appt = new AppointmentResource(token)
    //     appt.pay(appointment_id)
    //         .then(res => {
    //             toast.success(res)
    //             let newData = appointments.Data.map(d => {
    //                 if (d.id == appointment_id)
    //                     return { ...d, PayoutSent: true }
    //                 else
    //                     return { ...d }
    //             })
    //             setAppointments({ ...appointments, Data: newData })
    //         })
    //         .catch(err => {
    //             toast.error("Error in Payout")
    //             console.log(err)
    //         })
    //         .finally(async () => {
    //             setLoading(false)
    //             setProcessing(null)
    //         })
    // }

    // const processRefund = async (appointment_id) => {
    //     setLoading(true)
    //     setProcessing(appointment_id)
    //     let token = await getIdTokenClaims()
    //     token = token.__raw
    //     setToken(token)
    //     let appt = new AppointmentResource(token)
    //     appt.pay(appointment_id)
    //         .then(res => {
    //             toast.success(res)
    //             let newData = appointments.Data.map(d => {
    //                 if (d.id == appointment_id)
    //                     return { ...d, PayoutSent: true }
    //                 else
    //                     return { ...d }
    //             })
    //             setAppointments({ ...appointments, Data: newData })
    //         })
    //         .catch(err => {
    //             toast.error("Error in Processing refund")
    //             console.log(err)
    //         })
    //         .finally(async () => {
    //             setLoading(false)
    //             setProcessing(null)
    //         })
    // }

    const fetchUserList = async (page, size) => {
        setLoading(true)
        let token = await getIdTokenClaims()
        token = token.__raw
        setToken(token)
        let userResource = new UserResource(token)
        userResource.list(page, size)
            .then(list => {
                setUsers({ ...list, PageNo: list.PageNo - 1 })
            })
            .catch(err => {
                toast.error("Failed to fetch Appointments!")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(async () => {
        if (!urlPage || !(urlPage > 0))
            navigate(`/users?page=1&perPage=20`);
        else if (!urlSize)
            navigate(`/users?page=${urlPage}&perPage=20`)
        else
            await fetchUserList(urlPage, urlSize)
    }, [urlPage, urlSize])

    const columns = [
        {
            field: 'firstName',
            headerName: 'First Name',
            editable: false,
            headerAlign: "center",
            flex: 1
        },
        {
            field: 'lastName',
            headerName: 'Last Name',
            headerAlign: "center",
            editable: false,
            cellClassName: (params) => 'right-cell',
            flex: 1
        },
        {
            field: 'accountEmailAddress',
            headerName: 'Email',
            headerAlign: "center",
            editable: false,
            flex: 2,
        },
        {
            field: 'phone',
            headerName: 'Phone',
            headerAlign: "center",
            editable: false,
            flex: 2,
            renderCell: (params) => (
                <>{params.row.userPhoneNumbers && params.row.userPhoneNumbers[0]?.phoneNumber}</>
            )
        },
        {
            field: 'licenseNumber',
            headerName: 'DRE',
            headerAlign: "center",
            editable: false,
            flex: 1,
            renderCell: (params) => (
                <>{params.row.agentLicenseInfo?.licenseNumber}</>
            )
        },
        {
            field: 'status',
            headerName: 'Status',
            headerAlign: "center",
            editable: false,
            flex: 1,
            cellClassName: (params) => 'center-cell',
            renderCell: (params) => (
                <Chip size="small" label={`${params.row.activeStatus ? 'Active' : 'Suspended'}`} color={`${params.row.activeStatus ? 'success' : 'error'}`} />
            )
        },
        {
            field: "action",
            headerName: "",
            editable: false,
            flex: 2,
            renderCell: (params) => (
                <>
                    <Button
                        disabled={processing === params.row.id}
                        color="secondary" style={{ marginRight: 12 }}
                        variant="contained"
                        onClick={() => navigate(`/users/${params.row.id}`)}
                    >Edit</Button>
                </>

            )
        }
    ];
    return (
        <>
            <h1 style={{ textAlign: "left" }}>Users</h1>

            <div style={{ height: "80vh", width: '100%' }}>
                {/* <Stack direction="row" spacing={2} sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <Stack direction="row" spacing={2}>
                            <Chip onClick={() => handleTabChange("all")} label="All" color='primary' sx={{ pl: 2, pr: 2 }} />
                            <PendingChip onClick={() => handleTabChange("pending")} label="Pending" />
                            <SuccessChip onClick={() => handleTabChange("approved")} label="Approved" />
                            <DangerChip onClick={() => handleTabChange("rejected")} label="Rejected" />
                        </Stack>
                    </div>
                    <Button onClick={() => { setAddOpen(true) }} color='primary' variant="contained" ><AddCircleOutlineIcon sx={{ mr: 1 }} /> Create</Button>
                </Stack> */}
                {/* <h4 style={{ textAlign: "left" }}>Showing {activeTab} invitations</h4> */}
                <DataGrid
                    token={token}
                    disableColumnSelector
                    disableDensitySelector
                    disableSelectionOnClick
                    rows={users.Data}
                    rowCount={users.TotalCount}
                    rowsPerPageOptions={[20, 40, 60]}
                    paginationMode={"server"}
                    density="compact"
                    columns={columns}
                    page={users.PageNo}
                    pageSize={users.PageSize}
                    onPageChange={(page) => {
                        navigate(`/users?page=${page + 1}&perPage=${urlSize}`)
                        // setAppointments({ ...appointments, PageNo: page })
                        // fetchAppointmentList(page + 1, appointments.PageSize)
                    }}
                    onPageSizeChange={(size) => {
                        navigate(`/users?page=${users.PageNo + 1}&perPage=${size}`)
                        // setAppointments({ ...appointments, PageSize: size })
                        // fetchAppointmentList(appointments.PageNo, size)
                    }}
                    loading={loading}
                />
            </div>
        </>
    )
}