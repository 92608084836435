import { useEffect, useState } from "react"
import MiscResource from "../resources/MiscResource"
import { useAuth0 } from '@auth0/auth0-react';
import { CKEditor } from '@ckeditor/ckeditor5-react';
import ClassicEditor from '@ckeditor/ckeditor5-build-classic';
import Button from "@mui/material/Button";
import { toast } from "react-toastify";
import { useUIContext } from "../contexts/UIContext";

export default function Terms() {
    let Misc = new MiscResource();
    const { getIdTokenClaims } = useAuth0()
    const [token, setToken] = useState(getIdTokenClaims())
    const [section1, setSection1] = useState('')
    const [section2, setSection2] = useState('')
    const [id, setId] = useState('')
    const {setLoading} = useUIContext()
    useEffect(async () => {
        setLoading(true)
        let token = await getIdTokenClaims()
        token = token.__raw
        setToken(token)
        let Misc = new MiscResource(token);
        Misc.fetchTermsOfService()
            .then(data => {
                setSection1(data.section1)
                setSection2(data.section2)
                setId(data.id)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(()=>{
                setLoading(false)
            })
    }, [])

    const handleSubmit = async () => {
        setLoading(true)
        let Misc = new MiscResource(token);
        Misc.updateTermsAndCondition({ section1, section2,id, paragraph: section1 + section2 })
            .then(data => {
                toast.success("Terms updated successfully")
                console.log(data)
            })
            .catch(err => {
                toast.error("Failed to udpate terms")
                console.log(err)
            })
            .finally(()=>{
                setLoading(false)
            })
    }
    return (
        <form>
            <div >
                <h4 style={{ textAlign: "left" }}>Section 1</h4>
                <div style={{ maxHeight: "80vh", overflow: "scroll" }}>
                    <CKEditor
                        editor={ClassicEditor}
                        data={section1}
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            setSection1(data)
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                    />
                </div>
                <h4 style={{ textAlign: "left" }}>Section 2</h4>

                <div style={{ maxHeight: "80vh", overflow: "scroll" }}>
                    <CKEditor
                        editor={ClassicEditor}
                        data={section2}
                        onReady={editor => {
                            // You can store the "editor" and use when it is needed.
                            console.log('Editor is ready to use!', editor);
                        }}
                        onChange={(event, editor) => {
                            const data = editor.getData();
                            console.log({ event, editor, data });
                            setSection2(data)
                        }}
                        onBlur={(event, editor) => {
                            console.log('Blur.', editor);
                        }}
                        onFocus={(event, editor) => {
                            console.log('Focus.', editor);
                        }}
                    />
                </div>
                <div>
                    <Button fullWidth onClick={handleSubmit} variant="contained" color="primary">Submit</Button>
                </div>
            </div>
        </form>
    )
}