import * as React from 'react';
import { DataGrid } from '@mui/x-data-grid';
import InvitationResource from '../resources/InvitationResource';
import { useAuth0 } from '@auth0/auth0-react';
import { Button, Chip, Stack } from '@mui/material';
import styled from '@emotion/styled';
import { toast } from 'react-toastify';
import AddCircleOutlineIcon from '@mui/icons-material/AddCircleOutline';
import AddInvite from '../components/Invites/AddInvite';

const SuccessChip = styled(Chip)`
background-color: green;
color: white;
`

const DangerChip = styled(Chip)`
background-color: red;
color: white;
`

const PendingChip = styled(Chip)`
background-color: #ffeb3b;
color: black;
`
const statusNums = {
    "all": 3,
    "pending": 2,
    "approved": 1,
    "rejected": 0
}
export default function Invitations() {
    const [invites, setInvites] = React.useState([])
    const { getIdTokenClaims } = useAuth0()
    const [token, setToken] = React.useState(getIdTokenClaims())
    const [loadingRecord, setLoadingRecord] = React.useState(null)
    const [loading, setLoading] = React.useState(true)
    const [activeTab, setActiveTab] = React.useState("all")
    const [re, setRe] = React.useState(false)
    const [addOpen, setAddOpen] = React.useState(false);

    const handleApprove = (id, status) => {
        setLoadingRecord(id)
        const Invitation = new InvitationResource(token)
        Invitation.approveInvitationRequest(id, status)
            .then(res => {
                console.log(res)
                toast.success(res, { position: "bottom-right" })
                setRe(!re)
            })
            .catch(err => {
                console.log(err)
                toast.error("Failed to approve invitation", { position: "bottom-right" })
            })
            .finally(() => {
                setLoadingRecord(null)
            })
    }

    React.useEffect(async () => {
        let token = await getIdTokenClaims()
        token = token.__raw
        setToken(token)
        const Invitation = new InvitationResource(token)
        setLoading(true)
        Invitation.fetchInvitaionRequests(statusNums[activeTab])
            .then(res => {
                console.log(res)
                setInvites(res)
            })
            .catch(err => {
                console.log(err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [activeTab, re])

    const handleTabChange = (tab) => {
        setActiveTab(tab)
    }


    const columns = [
        {
            field: 'firstName',
            headerName: 'First name',
            editable: false,
            flex: 1
        },
        {
            field: 'lastName',
            headerName: 'Last name',
            editable: false,
            flex: 1
        },
        {
            field: 'email',
            headerName: 'Email',
            editable: false,
            flex: 1
        },
        {
            field: 'phone',
            headerName: 'Phone',
            editable: false,
            flex: 1
        },
        {
            field: "action",
            headerName: "Action",
            sortable: false,
            flex: 1,
            renderCell: (params) => {
                // console.log("APPROVE_PARAM", params)
                return (
                    <>
                        {
                            params.row.IsApproved === 0 &&
                            <Button
                                disabled
                                variant='contained'>
                                Rejected
                            </Button>
                        }
                        {
                            params.row.IsApproved === 1 &&
                            <Button
                                disabled={params.row.id == loadingRecord || params.row.IsApproved == 1}
                                variant='contained'>
                                Approved
                            </Button>
                        }
                        {
                            params.row.IsApproved === 2 &&
                            <>
                                <Button
                                    disabled={params.row.id == loadingRecord}
                                    color="secondary"
                                    variant='contained'
                                    onClick={() => { handleApprove(params.row.id, 1) }}>
                                    Approve
                                </Button>
                                <Button
                                    sx={{ ml: 2, background: "red" }}
                                    disabled={params.row.id == loadingRecord}
                                    variant='contained'
                                    onClick={() => { handleApprove(params.row.id, 0) }}>
                                    Reject
                                </Button>
                            </>
                        }
                    </>
                )
            }
        }
    ];
    return (
        <>
            <AddInvite open={addOpen} handleClose={() => setAddOpen(false)} />
            <div style={{ height: "80vh", width: '100%' }}>
                <h1 style={{ textAlign: "left" }}>Invitations</h1>
                <Stack direction="row" spacing={2} sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <Stack direction="row" spacing={2}>
                            <Chip onClick={() => handleTabChange("all")} label="All" color='primary' sx={{ pl: 2, pr: 2 }} />
                            <PendingChip onClick={() => handleTabChange("pending")} label="Pending" />
                            <SuccessChip onClick={() => handleTabChange("approved")} label="Approved" />
                            <DangerChip onClick={() => handleTabChange("rejected")} label="Rejected" />
                        </Stack>
                    </div>
                    <Button onClick={() => { setAddOpen(true) }} color='primary' variant="contained" ><AddCircleOutlineIcon sx={{ mr: 1 }} /> Create</Button>
                </Stack>
                <h4 style={{ textAlign: "left" }}>Showing {activeTab} invitations</h4>
                <DataGrid
                    token={token}
                    disableColumnSelector
                    disableDensitySelector
                    disableSelectionOnClick
                    rows={invites}
                    columns={columns}
                    pageSize={8}
                    rowsPerPageOptions={[8]}
                    loading={loading}
                // checkboxSelection
                />
            </div>
        </>
    )
}
