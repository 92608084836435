import axios from "axios";
import urls from "../urls";

class MiscResource {

    constructor(token) {
        this.token = token;
    }

    fetchTermsOfService() {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_BASE_URL}${urls.fetchTerms}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    }

    fetchPrivacyPolicy() {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_BASE_URL}${urls.fetchPrivacyPolicy}`)
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    }

    updateTermsAndCondition(data) {
        return new Promise((resolve, reject) => {
            axios.post(
                `${process.env.REACT_APP_BASE_URL}${urls.updateTerms}`,
                data,
                {
                    headers: { "Authorization": "Bearer " + this.token }
                }
            )
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    }
}

export default MiscResource;