import { useAuth0 } from '@auth0/auth0-react';
import VisibilityIcon from '@mui/icons-material/Visibility';
import { IconButton } from "@mui/material";
import Button from "@mui/material/Button";
import { DataGrid } from "@mui/x-data-grid";
import { useEffect, useState } from "react";
import { useLocation, useNavigate } from "react-router-dom";
import { toast } from "react-toastify";
import "../assets/css/grid.css";
import SingleAppointmentDialog from '../components/Appointments/SingleAppointmentDialog';
import AppointmentResource from "../resources/AppointmentResource";

const { DateTime } = require("luxon");

export default function Appointments() {
    const [loading, setLoading] = useState(false)
    const { getIdTokenClaims } = useAuth0()
    const [token, setToken] = useState(getIdTokenClaims())
    const location = useLocation()
    const navigate = useNavigate()
    const params = new URLSearchParams(location.search)
    const urlPage = params.get("page")
    const urlSize = params.get("perPage")
    const [appointments, setAppointments] = useState({ Data: [], PageNo: 0, PageSize: urlSize ?? 20 })
    const [processing, setProcessing] = useState(null)
    // Single Appt window open/close
    const [sOpen, setSOpen] = useState(false)
    const [openAppointment, setOpenAppointment] = useState(null)

    const getStatatus = (appt) => {
        let status = ''
        if (!appt.Paymentid)
            status = "Not Published"
        if (!appt.IsAssigned)
            status = 'Open'
        if (appt.Completed)
            status = 'Submitted'
        if (appt.ApproveCompletion)
            status = "Approved"
        if (appt.IsCanceled)
            status = "Canceled"
        return status
    }

    const processPayout = async (appointment_id) => {
        setLoading(true)
        setProcessing(appointment_id)
        let token = await getIdTokenClaims()
        token = token.__raw
        setToken(token)
        let appt = new AppointmentResource(token)
        appt.pay(appointment_id)
            .then(res => {
                toast.success(res)
                let newData = appointments.Data.map(d => {
                    if (d.id == appointment_id)
                        return { ...d, PayoutSent: true }
                    else
                        return { ...d }
                })
                setAppointments({ ...appointments, Data: newData })
            })
            .catch(err => {
                toast.error("Error in Payout")
                console.log(err)
            })
            .finally(async () => {
                setLoading(false)
                setProcessing(null)
            })
    }

    const processRefund = async (appointment_id) => {
        setLoading(true)
        setProcessing(appointment_id)
        let token = await getIdTokenClaims()
        token = token.__raw
        setToken(token)
        let appt = new AppointmentResource(token)
        appt.pay(appointment_id)
            .then(res => {
                toast.success(res)
                let newData = appointments.Data.map(d => {
                    if (d.id == appointment_id)
                        return { ...d, PayoutSent: true }
                    else
                        return { ...d }
                })
                setAppointments({ ...appointments, Data: newData })
            })
            .catch(err => {
                toast.error("Error in Processing refund")
                console.log(err)
            })
            .finally(async () => {
                setLoading(false)
                setProcessing(null)
            })
    }

    const fetchAppointmentList = async (page, size) => {
        setLoading(true)
        let token = await getIdTokenClaims()
        token = token.__raw
        setToken(token)
        let appt = new AppointmentResource(token)
        appt.list(page, size)
            .then(list => {
                setAppointments({ ...list, PageNo: list.PageNo - 1 })
            })
            .catch(err => {
                toast.error("Failed to fetch Appointments!")
            })
            .finally(() => {
                setLoading(false)
            })
    }

    useEffect(async () => {
        if (!urlPage || !(urlPage > 0))
            navigate(`/appointments?page=1&perPage=20`);
        else if (!urlSize)
            navigate(`/appointments?page=${urlPage}&perPage=20`)
        else
            await fetchAppointmentList(urlPage, urlSize)
    }, [urlPage, urlSize])

    const columns = [
        {
            field: 'appointmentType',
            headerName: 'Appointment Type',
            editable: false,
            headerAlign: "center",
            flex: 1
        },
        {
            field: 'PrimaryPrice',
            headerName: 'Price ($)',
            headerAlign: "center",
            editable: false,
            cellClassName: (params) => 'right-cell',
            flex: 1
        },
        {
            field: 'status',
            headerName: 'Status',
            headerAlign: "center",
            editable: false,
            flex: 1,
            renderCell: (params) => (
                <>{getStatatus(params.row)}</>
            )
        },
        {
            field: 'payout',
            headerName: 'Payout',
            headerAlign: "center",
            editable: false,
            flex: 1,
            renderCell: (params) => (
                <>{!params.row.PayoutSent ? 'Not sent yet' : params.row.Settled ? "Settled" : params.row.PayoutSent ? "Sent" : "Unknown"}</>
            )
        },
        {
            field: "listingAgent",
            headerName: "Primary Agent",
            headerAlign: "center",
            editable: false,
            flex: 1,
            renderCell: (params) => (
                <>{params.row.ListingAgentInfo?.firstName}</>
            )
        },
        {
            field: "showingAgent",
            headerName: "Showing Agent",
            headerAlign: "center",
            editable: false,
            flex: 1,
            renderCell: (params) => (
                <>{params.row.ShowingAgentInfo?.firstName}</>
            )
        },
        // {
        //     field: "Paymentid",
        //     headerName: "Payment Id",
        //     editable: false,
        //     flex: 1
        // },
        {
            field: 'appointmentDate',
            headerName: 'Appointment date',
            headerAlign: "center",
            editable: false,
            flex: 2,
            renderCell: (params) => (
                <>{DateTime.fromISO(params.row.appointmentDate).toFormat('LL/dd/yyyy t')}</>
            )
        },
        {
            field: "action",
            headerName: "",
            editable: false,
            flex: 2,
            renderCell: (params) => (
                <>
                    <IconButton
                        color="primary" style={{ marginRight: 12 }}
                        variant="contained"
                        onClick={() => { setSOpen(true); setOpenAppointment(params.row) }}
                    ><VisibilityIcon fontSize="large" /></IconButton>
                    {
                        params.row.ApproveCompletion && !params.row.PayoutSent &&
                        <>
                            <Button
                                color="primary" style={{ marginRight: 12 }}
                                variant="contained"
                                onClick={() => { }}
                            >View</Button>
                            <Button
                                disabled={processing === params.row.id}
                                color="secondary" style={{ marginRight: 12 }}
                                variant="contained"
                                onClick={() => processPayout(params.row.id)}
                            >Payout</Button>
                            <Button
                                disabled={processing === params.row.id}
                                color="primary" variant="contained"
                                onClick={() => processRefund(params.row.id)}
                            >Refund</Button>
                        </>
                    }
                </>
            )
        }
    ];
    return (
        <>
            <h1 style={{ textAlign: "left" }}>Appointments</h1>
            {openAppointment &&
                <SingleAppointmentDialog open={sOpen} handleClose={() => setSOpen(false)} appointment={openAppointment} />
            }
            <div style={{ height: "80vh", width: '100%' }}>
                {/* <Stack direction="row" spacing={2} sx={{ mb: 2, display: "flex", justifyContent: "space-between" }}>
                    <div>
                        <Stack direction="row" spacing={2}>
                            <Chip onClick={() => handleTabChange("all")} label="All" color='primary' sx={{ pl: 2, pr: 2 }} />
                            <PendingChip onClick={() => handleTabChange("pending")} label="Pending" />
                            <SuccessChip onClick={() => handleTabChange("approved")} label="Approved" />
                            <DangerChip onClick={() => handleTabChange("rejected")} label="Rejected" />
                        </Stack>
                    </div>
                    <Button onClick={() => { setAddOpen(true) }} color='primary' variant="contained" ><AddCircleOutlineIcon sx={{ mr: 1 }} /> Create</Button>
                </Stack> */}
                {/* <h4 style={{ textAlign: "left" }}>Showing {activeTab} invitations</h4> */}
                <DataGrid
                    token={token}
                    disableColumnSelector
                    disableDensitySelector
                    disableSelectionOnClick
                    rows={appointments.Data}
                    rowCount={appointments.TotalCount}
                    rowsPerPageOptions={[20, 40, 60]}
                    paginationMode={"server"}
                    density="compact"
                    columns={columns}
                    page={appointments.PageNo}
                    pageSize={appointments.PageSize}
                    onPageChange={(page) => {
                        navigate(`/appointments?page=${page + 1}&perPage=${urlSize}`)
                        // setAppointments({ ...appointments, PageNo: page })
                        // fetchAppointmentList(page + 1, appointments.PageSize)
                    }}
                    onPageSizeChange={(size) => {
                        navigate(`/appointments?page=${appointments.PageNo + 1}&perPage=${size}`)
                        // setAppointments({ ...appointments, PageSize: size })
                        // fetchAppointmentList(appointments.PageNo, size)
                    }}
                    loading={loading}
                />
            </div>
        </>
    )
}