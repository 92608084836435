import * as React from 'react';
import Button from '@mui/material/Button';
import Dialog from '@mui/material/Dialog';
import ListItemText from '@mui/material/ListItemText';
import ListItem from '@mui/material/ListItem';
import List from '@mui/material/List';
import Divider from '@mui/material/Divider';
import AppBar from '@mui/material/AppBar';
import Toolbar from '@mui/material/Toolbar';
import IconButton from '@mui/material/IconButton';
import Typography from '@mui/material/Typography';
import CloseIcon from '@mui/icons-material/Close';
import Slide from '@mui/material/Slide';
import { Avatar, Grid, ListItemIcon } from '@mui/material';

const Transition = React.forwardRef(function Transition(props, ref) {
    return <Slide direction="up" ref={ref} {...props} />;
});

export default function FullScreenDialog({ open, handleClose, appointment }) {

    return (
        <Dialog
            fullScreen
            open={open}
            onClose={handleClose}
            TransitionComponent={Transition}
        >
            <AppBar sx={{ position: 'relative', backgroundColor:"#e9e6e6" }} color={"secondary"}>
                <Toolbar>
                    <IconButton
                        edge="start"
                        color="inherit"
                        onClick={handleClose}
                        aria-label="close"
                    >
                        <CloseIcon />
                    </IconButton>
                    <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
                        Appointment Details
                    </Typography>
                </Toolbar>
            </AppBar>
            <div style={{ marginTop: 24, marginLeft: 12, marginRight: 12 }}>
                <Grid container>
                    <Grid xs={12} md={4}>
                        <strong>Appointment Info</strong>
                        <List>
                            <ListItem>
                                <ListItemText primary="Appointment Type" secondary={appointment.appointmentType} />
                            </ListItem>
                            <Divider />
                            <ListItem>
                                <ListItemText
                                    primary="Appointment Time"
                                    secondary={appointment.appointmentDate}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Price"
                                    secondary={appointment.price}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Price for primary"
                                    secondary={appointment.PrimaryPrice}
                                />
                            </ListItem>
                            <ListItem>
                                <ListItemText
                                    primary="Total Paid"
                                    secondary={appointment.TotalAmount}
                                />
                            </ListItem>
                            {appointment.languages &&
                                <ListItem>
                                    <ListItemText
                                        primary="Languages"
                                        secondary={appointment.languages.join()}
                                    />
                                </ListItem>
                            }

                        </List>
                    </Grid>
                    <Grid xs={12} sm={6} md={4}>
                        <strong>Agents</strong>
                        <List>
                            <ListItem>
                                <strong>Primary Agent</strong>
                                <br />
                            </ListItem>
                            <ListItem>
                                <ListItemIcon>
                                    <Avatar src={appointment.ListingAgentInfo?.ProfilePicture}></Avatar>
                                </ListItemIcon>
                                {` ${appointment.ListingAgentInfo?.firstName} ${appointment.ListingAgentInfo?.lastName}`}
                                <br />
                                {appointment.ListingAgentInfo?.accountEmailAddress}
                            </ListItem>
                            {
                                appointment.ShowingAgentInfo &&
                                <>
                                    <ListItem>
                                        <strong>Showing Agent</strong>
                                        <br />
                                    </ListItem>
                                    <ListItem>
                                        <ListItemIcon>
                                            <Avatar src={appointment.ShowingAgentInfo?.ProfilePicture}></Avatar>
                                        </ListItemIcon>
                                        {` ${appointment.ShowingAgentInfo?.firstName} ${appointment.ShowingAgentInfo?.lastName}`}
                                        <br />
                                        {appointment.ShowingAgentInfo?.accountEmailAddress}
                                    </ListItem>
                                </>
                            }
                            <ListItem>
                                <strong>Listing Address</strong>
                                <br />
                            </ListItem>
                            {
                                appointment.listingAddress.map((address, index) => (
                                    <>
                                        <ListItem key={`la-${index}`}>
                                            {`${address.mls}, ${address.line}, ${address.city}, ${address.state}, ${address.postal_code}`}
                                        </ListItem>
                                        <img src={address.Photo} style={{ maxWidth: "70%" }}></img>
                                    </>
                                ))
                            }
                           
                        </List>
                    </Grid>
                    <Grid item xs={12} sm={6} md={4}>
                        <ListItem>
                            <strong>Payment Details</strong>
                        </ListItem>
                        <ListItem>
                            PaymentID: {appointment.Paymentid}
                        </ListItem>
                        <ListItem>
                            Payment Status: {appointment.PaymentStatus}
                        </ListItem>
                    </Grid>
                </Grid>
            </div>

        </Dialog>
    );
}