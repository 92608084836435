import { createContext, useContext, useState } from "react";

const UIContext = createContext({ loading: false, setLoading: ()=>{} })

const useUIContext = ()=>useContext(UIContext)

const UIContextProvider = ({ children }) => {
    const [loading, setLoading] = useState(false)
    return (
        <UIContext.Provider
            value={{
                loading: loading,
                setLoading: setLoading
            }}
        >
            {children}
        </UIContext.Provider>
    )
}

export {
    UIContext,
    useUIContext,
    UIContextProvider
}