import axios from "axios";
import urls from "../urls";

class UserResource {

    constructor(token) {
        this.token = token;
    }
    /**
     * Fetch Users
     * @returns { Data:[]}
     */
    list(page,size) {
        return new Promise((resolve, reject) => {
            axios.get(
                `${process.env.REACT_APP_BASE_URL}${urls.fetchUsers}?PageNo=${page}&PageSize=${size}`,
                {
                    headers: {
                        "Authorization": `Bearer ${this.token}`
                    }
                }
            )
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    }

    /**
     * Fetch Single User
     * @param {string} id Id of user
     */
    show(id) {
        return new Promise((resolve, reject) => {
            axios.get(
                `${process.env.REACT_APP_BASE_URL}${urls.showUser}?id=${id}`,
                {
                    headers: {
                        "Authorization": `Bearer ${this.token}`
                    }
                }
            )
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => reject(err))
        })
    }

    /**
     * Update User
     */
    update(userProfile) {
        return new Promise((resolve, reject) => {
            axios.post(
                `${process.env.REACT_APP_BASE_URL}${urls.updateUser}`,
                userProfile,
                {
                    headers: {
                        "Authorization": `Bearer ${this.token}`
                    }
                }
            )
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => reject(err))
        })
    }


}

export default UserResource;