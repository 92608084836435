import urls from "../urls"
import axios from "axios";

class AppointmentResource {
    constructor(token) {
        this.token = token
    }

    /**
     * @param {number} Page No. 
    * @param {number} Per Page
     * @returns {{ PageNo : Number, Data: Array, PageSize: Number, TotalCount: Number }} Result
     */
    list(page, size) {
        return new Promise((resolve, reject) => {
            axios.post(
                `${process.env.REACT_APP_BASE_URL}${urls.fetchApppointments}`,
                {
                    PageNo: page,
                    PageSize: size,
                    IsPaidAppointments: false
                },
                {
                    headers: { "Authorization": "Bearer " + this.token }
                }
            )
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    reject(err)
                })
        })
    }

    /**
     * @param {string} appointment_id
     */
    pay(appointment_id) {
        return new Promise((resolve, reject) => {
            axios.get(
                `${process.env.REACT_APP_BASE_URL}${urls.manualPayout}?appointment_id=${appointment_id}`,
                {
                    headers: { "Authorization": "Bearer " + this.token }
                }
            )
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => reject(err))
        })
    }

    /**
     * 
     * @param {string} appointment_id 
     */
    refund(appointment_id) {
        return new Promise((resolve, reject) => {
            axios.get(
                `${process.env.REACT_APP_BASE_URL}${urls.manualRefund}?appointment_id=${appointment_id}`,
                {
                    headers: { "Authorization": "Bearer " + this.token }
                }
            )
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => reject(err))
        })
    }

}

export default AppointmentResource;