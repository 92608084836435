import axios from "axios";
import urls from "../urls";

class InvitationResource {
    constructor(token) {
        this.token = token;
    }
    fetchInvitaionRequests(status = 2) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_BASE_URL}/api/GetInvitationRequest?IsApproved=${status}`,
                {
                    headers: { "Authorization": "Bearer " + this.token }
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    }

    approveInvitationRequest(id, status) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BASE_URL}/api/SetInvitationStatus`,
                { id, IsApproved: status },
                {
                    headers: { "Authorization": "Bearer " + this.token }
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    }

    checkInvitationExists(email) {
        return new Promise((resolve, reject) => {
            axios.get(`${process.env.REACT_APP_BASE_URL}${urls.checkInvite}?email=${email}`,
                {
                    headers: { "Authorization": "Bearer " + this.token }
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    }

    createInvitation(data) {
        return new Promise((resolve, reject) => {
            axios.post(`${process.env.REACT_APP_BASE_URL}${urls.createInvite}`,
                data,
                {
                    headers: { "Authorization": "Bearer " + this.token }
                })
                .then(res => {
                    resolve(res.data)
                })
                .catch(err => {
                    console.log(err)
                    reject(err)
                })
        })
    }
}

export default InvitationResource;
