const urls = {
    checkInvite: "/api/CheckInvite",
    createInvite: "/api/RequestInvite",
    fetchApppointments: "/api/FetchAppointments",
    fetchTerms: "/api/GetTermAndCondition",
    fetchPrivacyPolicy: "/api/getPrivacyPolicy",
    updateTerms: "/api/SetTermAndCondition",
    manualPayout: "/api/ManualPayout",
    manualRefund: "/api/ManualRefund",
    fetchUsers: "/api/GetUsers",
    showUser: "/api/GetUserDetailsById",
    updateUser: "/api/UpdateUserById"
}
export default urls;