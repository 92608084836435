import { Button, CircularProgress } from "@mui/material";

export default function LoadingButton(props) {
    return (
        <Button
            {...props}
            disabled={props.loading}
        >
            {
                props.loading ?
                    <CircularProgress />
                    :
                    props.children
            }
        </Button>
    )
}