import { useAuth0 } from "@auth0/auth0-react";
import { Button, FormControlLabel, Switch } from "@mui/material";
import Card from "@mui/material/Card";
import FormControl from '@mui/material/FormControl';
import Grid from "@mui/material/Grid";
import InputLabel from '@mui/material/InputLabel';
import MenuItem from '@mui/material/MenuItem';
import Select from '@mui/material/Select';
import TextField from "@mui/material/TextField";
import { AdapterLuxon } from '@mui/x-date-pickers/AdapterLuxon';
import { DateTimePicker } from '@mui/x-date-pickers/DateTimePicker';
import { LocalizationProvider } from '@mui/x-date-pickers/LocalizationProvider';
import { useFormik } from "formik";
import { useEffect } from "react";
import { useParams } from "react-router-dom";
import { toast } from "react-toastify";
import { useUIContext } from "../contexts/UIContext";
import UserResource from "../resources/UserResource";

export default function EditUser({ match }) {
    let { id } = useParams()
    // const [details, setDetails] = useState({ firstName: '', lastName: '' });
    const { getIdTokenClaims } = useAuth0();
    const { setLoading } = useUIContext();

    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            accountEmailAddress: '',
            agentLicenseInfo: null
        },
        onSubmit: async (values) => {
            setLoading(true)
            let token = await getIdTokenClaims()
            token = token.__raw
            let userResource = new UserResource(token)
            userResource.update(values)
                .then(data => {
                    toast.success("User Updated Successfully")
                })
                .catch(err => {
                    console.log("Error in updating User", err);
                    toast.error("Some error occured!")
                }).
                finally(() => {
                    setLoading(false)
                })
        }
    })
    useEffect(async () => {
        setLoading(true)
        let token = await getIdTokenClaims()
        token = token.__raw
        let userResource = new UserResource(token)
        userResource.show(id)
            .then((user) => {
                formik.setValues(user)
            })
            .catch(err => {
                console.log("Error fetching user", err)
            })
            .finally(() => {
                setLoading(false)
            })
    }, [])
    return (
        <>
            <h1 style={{ textAlign: "left" }}>Update User</h1>
            <form onSubmit={formik.handleSubmit}>
                <Card style={{ padding: "4vw", borderRadius:24 }}>
                    <Grid container spacing={2}>
                        <Grid item xs={6} md={4}>
                            <TextField
                                label="First Name"
                                name="firstName"
                                fullWidth
                                variant="outlined"
                                value={formik.values.firstName ?? ''}
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField
                                disabled
                                value={formik.values.lastName ?? ''}
                                name="lastName"
                                label="Last Name"
                                fullWidth variant="outlined"
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField label="Email"
                                name="accountEmailAddress"
                                value={formik.values.accountEmailAddress ?? ''}
                                fullWidth variant="outlined"
                                onChange={formik.handleChange}
                            />
                        </Grid>
                        <Grid item xs={12}>
                            <h3 style={{ textAlign: "left" }}>License Info</h3>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <TextField label="DRE"
                                name="licenseNumber"
                                value={formik.values.agentLicenseInfo?.licenseNumber ?? ''}
                                fullWidth variant="outlined"
                                onChange={(e) => {
                                    formik.setFieldValue("agentLicenseInfo", { ...formik.values.agentLicenseInfo, licenseNumber: e.target.value })
                                }}
                            />
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <LocalizationProvider dateAdapter={AdapterLuxon}>
                                <DateTimePicker
                                    InputProps={{
                                        fullWidth: true
                                    }}
                                    label="Date and Time"
                                    value={formik.values.agentLicenseInfo ? new Date(formik.values.agentLicenseInfo.verificationDate) : ''}
                                    onChange={(newDate) => {
                                        formik.setFieldValue("agentLicenseInfo", { ...formik.values.agentLicenseInfo, verificationDate: newDate })
                                    }}
                                    renderInput={(params) => <TextField fullWidth {...params} />}
                                />
                            </LocalizationProvider>
                        </Grid>
                        <Grid item xs={6} md={4}>
                            <FormControl fullWidth>
                                <InputLabel id="purposes-label">Purpose</InputLabel>
                                <Select
                                    fullWidth
                                    labelId="purposes"
                                    id="purposes"
                                    value={formik.values.agentLicenseInfo?.purposes ?? ''}
                                    label="Purpose"
                                    onChange={(e) => {
                                        formik.setFieldValue("agentLicenseInfo", { ...formik.values.agentLicenseInfo, purposes: e.target.value })
                                    }}
                                >
                                    <MenuItem value="">
                                        <em>None</em>
                                    </MenuItem>
                                    <MenuItem value={"both"}>Both</MenuItem>
                                    <MenuItem value={"outsource"}>Outsource</MenuItem>
                                    <MenuItem value={"take"}>Take Appointments</MenuItem>
                                </Select>
                            </FormControl>
                        </Grid>
                        <Grid item xs={6} md={4} display="flex" justifyContent="flex-start">
                            <FormControlLabel
                                onChange={(e) => formik.setFieldValue("licensedAgent", e.target.checked)}
                                checked={formik.values.licensedAgent}
                                control={<Switch />}
                                label="Licensed Agent"
                            />
                        </Grid>
                        <Grid item xs={6} md={4} justifyContent="flex-start" display="flex">
                            <FormControlLabel
                                onChange={(e) => formik.setFieldValue("activeStatus", e.target.checked)}
                                checked={formik.values.activeStatus}
                                control={<Switch />}
                                label="Active"
                            />
                        </Grid>
                        <Grid item xs={12} sx={{ mt: 4 }}>
                            <Grid container>
                                <Grid item xs={12} sm={6} md={4}>
                                    <Button
                                        fullWidth
                                        type="submit"
                                        variant="contained"
                                        color="primary"
                                    >
                                        Submit
                                    </Button>
                                </Grid>
                            </Grid>
                        </Grid>
                    </Grid>
                </Card>
            </form>
        </>
    )
}