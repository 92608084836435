import styled from "@emotion/styled";
import CloseOutlined from "@mui/icons-material/CloseRounded";
import { Dialog, DialogActions, DialogContent, DialogTitle, IconButton, TextField } from "@mui/material";
import MuiGrid from "@mui/material/Grid";
import { useFormik } from "formik";
import { toast } from "react-toastify";
import Invitation from "../../resources/InvitationResource";
import LoadingButton from "../LoadingButton";

const Grid = styled(MuiGrid)`
padding: 10px;
`
const Invitations = new Invitation()
export default function AddInvite({ open, handleClose }) {
    const formik = useFormik({
        initialValues: {
            firstName: '',
            lastName: '',
            email: '',
            phone: ''
        },
        onSubmit: (values) => {
            console.log(values)
            //startLoading()
            Invitations.createInvitation(values)
                .then(res => {
                    console.log()
                    formik.resetForm()
                    toast.success("Invite created!", { position: "bottom-right" })
                })
                .catch(err => {
                    console.log()
                    toast.error("Some error ocured! Please try again", { position: "bottom-right" })
                })
            //     .finally(() => stopLoading())
        }
    })

    return (
        <>
            <Dialog fullWidth maxWidth="sm" open={open} onClose={handleClose}>
                <form onSubmit={formik.handleSubmit}>
                    <DialogTitle>
                    <div style={{ display: "flex", justifyContent: "space-between" }}>
                        Create an Invite
                        <IconButton onClick={handleClose}>
                            <CloseOutlined />
                        </IconButton>
                    </div>
                    </DialogTitle>

                    <DialogContent>
                        <Grid container>
                            <Grid item xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    name="firstName"
                                    value={formik.values.firstName}
                                    onChange={formik.handleChange}
                                    label="First Name"
                                    variant="outlined"
                                    error={formik.errors.firstName != undefined ? true : false}
                                    helperText={formik.errors.firstName}
                                />
                            </Grid>
                            <Grid xs={12} md={6}>
                                <TextField
                                    fullWidth
                                    type="text"
                                    className="form-control"
                                    name="lastName"
                                    value={formik.values.lastName}
                                    onChange={formik.handleChange}
                                    label="Last Name"
                                    variant="outlined"
                                    error={formik.errors.lastName != undefined ? true : false}
                                    helperText={formik.errors.lastName}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <TextField
                                    name="email"
                                    value={formik.values.email}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    variant="outlined"
                                    label="Email"
                                    helperText={formik.errors.email}
                                    error={formik.errors.email ? true : false}
                                />
                            </Grid>
                            <Grid xs={12}>
                                <TextField
                                    name="phone"
                                    value={formik.values.phone}
                                    onChange={formik.handleChange}
                                    fullWidth
                                    variant="outlined"
                                    label="Phone"
                                    helperText={formik.errors.phone}
                                    error={formik.errors.phone ? true : false}
                                />
                            </Grid>
                        </Grid>
                    </DialogContent>
                    <DialogActions>
                        <Grid container>
                            <Grid item xs={12}>
                                <LoadingButton loading={formik.isSubmitting} fullWidth type="submit" variant="contained" color="primary">Create</LoadingButton>
                            </Grid>
                        </Grid>
                    </DialogActions>
                </form>
            </Dialog>
        </>
    )
}