import './App.css';
import MiniDrawer from './components/Drawer';
import { BrowserRouter } from 'react-router-dom';
import { Routes, Route, Link } from "react-router-dom";
import Invitations from './Pages/Invitations';
import Users from './Pages/Users';
import Appointments from './Pages/Appointments';
import { withAuthenticationRequired } from '@auth0/auth0-react';
import RealtourLoading from './components/RealtourLoading';
import { ToastContainer, toast } from 'react-toastify';
import 'react-toastify/dist/ReactToastify.css';
import { Backdrop, CircularProgress, createTheme } from '@mui/material';
import { ThemeProvider } from '@emotion/react';
import Terms from './Pages/Terms';
import { useUIContext } from "./contexts/UIContext";
import EditUser from './Pages/EditUser';

const theme = createTheme({
  palette: {
    primary: {
      main: '#FF3D00',
    },
    secondary: {
      main: "#26E8B9",
    },
  }
})
function App() {
  const { loading } = useUIContext()
  return (
    <div className="App">
      <ThemeProvider theme={theme}>
        <ToastContainer />
        <Backdrop style={{ zIndex: 2500, color: "#fff" }} open={loading}>
          <CircularProgress color="inherit" />
        </Backdrop>
        <BrowserRouter>
          <Routes>
            <Route path="/" element={<MiniDrawer />}>
              <Route path="/invitations" element={<Invitations />} />
              <Route path="/users" element={<Users />} />
              <Route path="/users/:id" element={<EditUser />} />
              <Route path="/appointments" element={<Appointments />} />
              <Route path="/terms" element={<Terms />} />
            </Route>
          </Routes>
        </BrowserRouter>
      </ThemeProvider>
    </div >
  );
}

export default withAuthenticationRequired(App, { onRedirecting: () => <RealtourLoading /> });
